import './App.css'


import React, { useEffect, useState } from 'react';
import { auth } from './firebase-config';
import Login from './Login';
import CampUrls from './components/CampUrls';
import ProfileBadge from './ProfileBadge';
import SchedulePlanner from './SchedulePlanner';



function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in, you can use the 'user' object to get user information
        setCurrentUser(user);
        user.getIdTokenResult()
        .then((idTokenResult) => {
          // Access custom claims
          const { role } = idTokenResult.claims;
          console.log(idTokenResult)
          console.log(role)
          setUserRole(role);
        })
        .catch((error) => {
          console.log(error);
        });

      } else {
        // No user is signed in.
        setCurrentUser(null);
      }



      setLoading(false);
    });


    // Cleanup subscription on unmount
    return () => unsubscribe();

  }, []); // Empty dependency array means this effect runs only once on mount


  if (loading) {
    return <div className="App">Loading...</div>; // Display loading indicator
  }

  return (
    <div className="App">
      <header className="App-header">
        <h2> FillTime </h2>
        <ProfileBadge />
      </header>

      <div className='container'>
        {currentUser ? (
          <div>
            {
              <div>

                <SchedulePlanner />


                {userRole === 'admin' && <CampUrls />}
              </div>
            }
          </div>
        ) : (
          <Login />
        )}
      </div>
    </div>



  );
}

export default App;
