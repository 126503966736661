import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyC74id9bnOBzXjNP0WDoIUf6xy2a6OsqWI",
  authDomain: "easycamp-d2664.firebaseapp.com",
  projectId: "easycamp-d2664",
  storageBucket: "easycamp-d2664.appspot.com",
  messagingSenderId: "198678508292",
  appId: "1:198678508292:web:1614cf6b811769027a1651",
  measurementId: "G-VYDV3L37Q1",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
