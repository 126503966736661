import React, { useEffect, useState } from 'react';
import { auth } from './firebase-config'; // Ensure this path is correct for your firebase-config file

const ProfileBadge = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in, you can use the 'user' object to get user information
        setCurrentUser(user);
      } else {
        // No user is signed in.
        setCurrentUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []); // Empty dependency array means this effect runs only once on mount

  return (
    <div>
      {currentUser ? (
        <div className='email-address'> {currentUser.email}
        </div>
      ) : (
        <p>No user is currently signed in.</p>
      )}
    </div>
  );
};

export default ProfileBadge;