import React, { useState, useEffect, useCallback  } from "react";
import { db } from "../firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { functions } from '../firebase-config'; // Ensure this path matches where you initialize Firebase
import { httpsCallable } from 'firebase/functions';


const CampUrls = () => {
  const [urls, setUrls] = useState([]);
  const [newUrl, setNewUrl] = useState("");
  const urlsCollectionRef = collection(db, "camp-urls");

  // Wrap fetchUrls in useCallback to prevent it from being recreated on every render
  const fetchUrls = useCallback(async () => {
    const data = await getDocs(urlsCollectionRef);
    setUrls(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  }, [urlsCollectionRef]); // Specify urlsCollectionRef as a dependency

  useEffect(() => {
    fetchUrls(); // Call fetchUrls on component mount
  }, [fetchUrls]); // fetchUrls is now stable and won't cause useEffect to re-run unnecessarily



  const addUrl = async () => {
    if (!newUrl) return; // Check if the input is not empty
    
    // Initialize the Cloud Function
    const createNewCampUrl = httpsCallable(functions, 'createNewCampUrl');
    
    // Call the function and pass the new URL
    try {
      await createNewCampUrl({ url: newUrl });
      setNewUrl(""); // Reset input field after submission
      await fetchUrls(); // Fetch the updated list of URLs to reflect the new addition
    } catch (error) {
      console.error("Error adding new URL:", error);
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    addUrl(); // Add the URL to Firestore
  };

  return (
    <div>
      <h2>Camp URLs</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Add new URL"
          value={newUrl}
          onChange={(e) => setNewUrl(e.target.value)}
        />
        <button type="submit">Add URL</button>
      </form>
      <ul>
        {urls.map((url) => (
          <li key={url.id}>
            <a href={url.url} target="_blank" rel="noopener noreferrer">
              {url.url}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CampUrls;
